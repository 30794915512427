import React from 'react'
import { IconButton } from '@mui/material'
import './getApp.css'
import playstore from '../assets/svg/googleplay.png'
import istore from '../assets/svg/appstore.png'
function GetApp() {
    return (
        <div className='container-fluid py-5 mt-5'>
            <h1 style={{ textAlign: 'center' }}>
                <span className='app_h1_black py-4'>Get </span>
                <span className='app_h1-red' style={{ color: '#DF181A' }}>The App</span>
            </h1>
            <p className=' app_text' style={{ textAlign: 'center' }}>MediCar has launched, aiming to improve patient access to care and make
                transportation to medical appointments more convenient and efficient.
                The app MediCar is available for download on
                both iOS and Android devices
            </p>
            <div className='marg2'>
                <a href="https://www.google.com" target="_blank" rel="noopener noreferrer">
                    <IconButton color="primary">
                        <img src={playstore} alt="Folder Icon" />
                    </IconButton>
                </a>
                <a href="https://www.google.com" target="_blank" rel="noopener noreferrer">
                    <IconButton color="secondary">
                        <img src={istore} alt="Notification Icon" />
                    </IconButton>
                </a>
            </div>
        </div>
    )
}

export default GetApp
