import React from 'react'
import './currentPrograms.css'
import img4 from '../assets/images/img4.png';
import img5 from '../assets/images/img5.png';
import img6 from '../assets/images/img6.png';
import img7 from '../assets/images/img7.png';
import medicarbucket from "../assets/images/medicarbucket.jpg"
import medicarservices from "../assets/images/medicarservice.jpg"

function CurrentPrograms() {
    return (
        <div className='container '>
            <div className=' row'>
                <div style={{ textAlign: 'center' }} className='col-lg-12 col-md-12 col-sm-12'>
                    <h2>
                        <span className='h1_black'>Current</span>
                        <span className='h1_black' style={{ color: '#DF181A' }}> Programs</span>
                    </h2>
                    <p className='program_text py-4' >
                        Facilities interested in signing up for our medical transportation services,
                        we would be happy to assist you. Please contact us at  <span style={{ color: '#DF181A' }}> info@medicarlgx.com </span>
                        and one of our representatives will reach out to you within <span style={{ color: '#DF181A' }}> 48hrs </span>. We will
                        guide you through the process of setting up an account and scheduling your
                        transportation needs. Our team will be available to answer any questions
                        you may have and to ensure that you receive the highest level of service.
                        Thank you for considering us for your medical transportation needs.
                    </p>
                </div>
                <div className='container-fluid imagespad'>
                    <div className='row py-4'>
                        <div className='col-lg-4 col-md-4 col-sm-4  '>

                            <img className='img-fluid' src={img4} />

                        </div>
                        <div className='col-sm-8'>
                            <div className='row'>
                                <div className='col-lg-4 col-md-4 col-sm-4 ' style={{ width: '50%' }}>
                                    <div className=''>
                                        <img className='img-fluid' src={img5} />
                                    </div>
                                </div>
                                <div className='col-lg-4 col-md-4 col-sm-4' style={{ width: '50%' }}>
                                    <div className=''>
                                        <img className='img-fluid' src={img6} />
                                    </div>
                                </div>
                            </div>
                            <div className=' col-8 pt-4' style={{ width: '100%' }}>
                                <div className=''>
                                    <img className='img-fluid' src={img7} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='col-lg-6 col-md-12 col-sm-12 d-flex align-items-center justify-content-center'>
                    <img style={{ width: '100%' }} src={medicarbucket} alt="Medicar Bucket" />
                </div>
                <div className='col-lg-6 col-md-12 col-sm-12 '>
                    <h3 className='work_h3'>We provide a pharmacy service</h3>
                    <p className='work_text' style={{ textAlign: 'justify' }}>We are proud to offer pharmacy delivery services to our valued customers. With our convenient delivery option, you can have your medications and healthcare products delivered right to your doorstep. We understand the importance of timely and hassle-free access to medication, especially for those who may have difficulty visiting a pharmacy in person. Our dedicated team ensures that your prescriptions are safely delivered, maintaining the highest standards of quality and confidentiality. Whether you are in need of regular medication refills or have an urgent prescription, our pharmacy delivery services are here to provide you with convenience, peace of mind, and exceptional customer service.</p>
                </div>

                <div className='col-lg-6 col-md-12 col-sm-12  order2'>
                    <h3 className='work_h3'>We provide a  lab service</h3>
                    <p className='work_text' style={{ textAlign: 'justify' }}>At MediCar, we specialize in providing reliable and efficient lab courier services tailored to meet the unique needs of our clients. With a focus on timeliness, security, and adherence to regulatory requirements, we ensure the safe and expedient transport of lab samples and specimens from collection points to testing facilities. Our experienced team is trained in proper packaging and handling procedures, guaranteeing the integrity and preservation of your valuable samples during transit. We understand the importance of maintaining the cold chain for temperature-sensitive specimens and take the necessary precautions to ensure their safe transportation. We maintain a clear chain of custody record, ensuring traceability and accountability throughout the transportation process. Compliance with local, national, and international regulations is a top priority.</p>
                </div>
                <div className='col-lg-6 col-md-12 col-sm-12 d-flex align-items-center justify-content-center'>
                    <img style={{ width: '100%' }} src={medicarservices} alt="Medicar Services" />
                </div>



            </div>

        </div>
    )
}

export default CurrentPrograms
