import React from 'react';
import { makeStyles } from '@mui/styles';
import { Typography, Container, Grid } from '@mui/material';
import Icon1 from '../assets/svg/onDmand.png';
import Icon2 from '../assets/svg/safe.png';
import Icon3 from '../assets/svg/Reliable.png';
import Icon4 from '../assets/svg/Efficient.png';
import './service.css'

const ServicesComponent = () => {

    return (
        <div className='container mt-4 mb-5' id='services'>
            <h2 className='mt-5 mb-5' style={{ textAlign: 'center' }}>
                <span className='h1_black'>Best</span> <span className='h1_red'>Services</span>
            </h2>
            <p className='service_para'>
                Our mission is to revolutionize healthcare transportation by providing a safe, reliable, and efficient transportation solution for medical facilities through our innovative technology platform. We are dedicated to improving access to healthcare by ensuring
                that patients and medical professionals can arrive at their destinations on time, every time.
            </p>
            <p className='service_para'>
                Our commitment to exceptional customer service and attention to detail sets us apart, We believe that by leveraging technology, we can make a
                meaningful impact on people's lives and contribute to a better, healthier world.
            </p>
            <div className='row text-center mt-5 mb-5'>
                <div className='col-lg-3 col-md-6 col-sm-12'>
                    <div>
                        <img src={Icon1} alt="Icon 1" />
                        <h5 className='icons_h5 pt-2'>
                            On Demand
                        </h5>
                        {/* <p className='icons_p'>
                            Description for Icon 1
                        </p> */}
                    </div>
                </div>
                <div className='col-lg-3 col-md-6 col-sm-12'>
                    <div>
                        <img src={Icon2} alt="Icon 2" />
                        <h5 className='icons_h5 pt-2'>
                            Safe
                        </h5>
                        {/* <p className='icons_p'>
                            Description for Icon 1
                        </p> */}
                    </div>
                </div>
                <div className='col-lg-3 col-md-6 col-sm-12'>
                    <div>
                        <img src={Icon3} alt="Icon 3" />
                        <h5 className='icons_h5 pt-2'>
                            Reliable
                        </h5>
                        {/* <p className='icons_p'>
                            Description for Icon 1
                        </p> */}
                    </div>
                </div>
                <div className='col-lg-3 col-md-6 col-sm-12'>
                    <div>
                        <img src={Icon4} alt="Icon 4" />
                        <h5 className='icons_h5 pt-2'>
                            Efficient
                        </h5>
                        {/* <p className='icons_p' >
                            Description for Icon 1
                        </p> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ServicesComponent;
