import 'bootstrap/dist/css/bootstrap.min.css';
import Banner from "./components/Banner";
import RowComponent from "./components/LearnMore";
import NavHead from "./components/NavHead";
import Navbar from "./components/Navbar";
import ServicesComponent from "./components/ServiceComponent";
import CurrentPrograms from './components/CurrentPrograms';
import Work from './components/Work';
import GetApp from './components/GetApp';
import Blogs from './components/Blogs';
import Footer from './components/Footer';

function App() {
  return (
    <div>
      <NavHead />
      <Navbar />
      <Banner />
      <ServicesComponent />
      <RowComponent />
      <CurrentPrograms />
      {/* <br></br> */}
      <Work />
      <GetApp />
      <Blogs />
      <Footer />
    </div>
  );
}

export default App;
