import React from 'react'
import './blogs.css'
import im1 from '../assets/images/img13.png';
import im2 from '../assets/images/img14.png';
import im3 from '../assets/images/img15.png';
function Blogs() {
    return (
        <div className='container-fluid blog_bg' id='blogs'>
            <div className='container spacing'>
                <h1 className='mt-5' style={{ textAlign: 'center' }}>
                    <span className='blog_h1_red'>Our</span>
                    <span className='blog_h1_black' style={{ color: '#DF181A' }}> News & Blogs</span>
                </h1>
                <p className='blog_text'>
                    MediCar offers a range of features tailored to meet the needs of patients who require
                    medical transportation. These features include wheelchair & stretcher accessibility,
                    GPS tracking of vehicles, and the ability to communicate directly with drivers.
                    The app also offers a transparent pricing model, with no hidden fees, making it a
                    cost-effective option for patients who require regular transportation to medical appointments.
                    "We understand the challenges that patients face when it comes to accessing medical care,
                    and transportation is often a significant barrier," said the founder of MediCar. "Our app
                    is designed to make transportation to medical appointments more convenient, reliable, and
                    affordable, ensuring that patients can get the care they need."
                    MediCar is currently available in select cities across the United States, with plans to
                    expand to more locations in the coming months.
                    This new app launch has the potential to revolutionize the way patients access medical
                    care and transportation, providing a much-needed solution to a common problem.

                </p>
                <div className='row '>
                    <div className='col-lg-4 col-md-6 col-sm-12'>
                        <div className='py-2'>
                            <img style={{ width: '100%' }} src={im1} />
                        </div>
                    </div>
                    <div className='col-lg-4 col-md-6 col-sm-12'>
                        <div className='py-2'>
                            <img style={{ width: '100%' }} src={im2} />
                        </div>
                    </div>
                    <div className='col-lg-4 col-md-6 col-sm-12'>
                        <div className='py-2'>
                            <img style={{ width: '100%' }} src={im3} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Blogs
