import React from 'react'
import img1 from '../assets/images/img8.png';
import img2 from '../assets/images/img9.png';
import img3 from '../assets/images/img10.png';
import img4 from '../assets/images/img11.png';
import img5 from '../assets/images/img12.png';
import './work.css'
function Work() {
    return (
        <div className='container-fluid work_bg' id='work'>

            <div className='container'>

                <div className='row '>
                    <div className='col-12'>
                        <div>
                            <h1 className='py-5 ' style={{ textAlign: 'center' }}>
                                <span className='span1_work_h1'>How Do</span> <span className='span2_work_h1' style={{ color: '#DF181A' }}> We Work</span>
                            </h1>
                        </div>
                    </div>
                    <div className='col-lg-6 col-md-6 col-sm-12 d-flex align-items-center justify-content-center'>
                        <img style={{ width: '100%' }} src={img1} />
                    </div>
                    <div className='col-lg-6 col-md-6 col-sm-12 '>
                        <h6 className='work_h6' style={{ color: '#DF181A' }}>Step 1</h6>
                        <h3 className='work_h3'>We provide a reliable and efficient service</h3>
                        <p className='work_text' style={{ textAlign: 'justify' }}>Our app is designed to provide a reliable and efficient service to our patients.
                            We ensure that our drivers are highly trained and equipped with the necessary
                            medical equipment to handle any emergency situation that may arise.</p>
                    </div>

                    <div className='col-lg-6 col-md-6 col-sm-12 '>
                        <h6 className='work_h6' >Step 2</h6>
                        <h3 className='work_h3'>We provide a reliable and efficient service</h3>
                        <p className='work_text' style={{ textAlign: 'justify' }}>Our app is designed to provide a reliable and efficient service to our patients.
                            We ensure that our drivers are highly trained and equipped with the necessary
                            medical equipment to handle any emergency situation that may arise.</p>
                    </div>
                    <div className='col-lg-6 col-md-6 col-sm-12 d-flex align-items-center justify-content-center'>
                        <img style={{ width: '100%' }} src={img2} />
                    </div>

                    <div className='col-lg-6 col-md-6 col-sm-12 d-flex align-items-center justify-content-center'>
                        <img style={{ width: '100%' }} src={img3} />
                    </div>
                    <div className='col-lg-6 col-md-6 col-sm-12 '>
                        <h6 className='work_h6' >Step 3</h6>
                        <h3 className='work_h3'>We provide a reliable and efficient service</h3>
                        <p className='work_text' style={{ textAlign: 'justify' }}>Our app is designed to provide a reliable and efficient service to our patients.
                            We ensure that our drivers are highly trained and equipped with the necessary
                            medical equipment to handle any emergency situation that may arise.</p>
                    </div>

                    <div className='col-lg-6 col-md-6 col-sm-12 '>
                        <h6 className='work_h6' >Step 4</h6>
                        <h3 className='work_h3'>We provide a reliable and efficient service</h3>
                        <p className='work_text' style={{ textAlign: 'justify' }}>Our app is designed to provide a reliable and efficient service to our patients.
                            We ensure that our drivers are highly trained and equipped with the necessary
                            medical equipment to handle any emergency situation that may arise.</p>
                    </div>
                    <div className='col-lg-6 col-md-6 col-sm-12 d-flex align-items-center justify-content-center'>
                        <img style={{ width: '100%' }} src={img4} />
                    </div>

                    <div className='col-lg-6 col-md-6 col-sm-12 d-flex align-items-center justify-content-center'>
                        <img style={{ width: '100%' }} src={img5} />
                    </div>
                    <div className='col-lg-6 col-md-6 col-sm-12 '>
                        <h6 className='work_h6' >Step 5</h6>
                        <h3 className='work_h3'>We provide a reliable and efficient service</h3>
                        <p className='work_text' style={{ textAlign: 'justify' }}>Our app is designed to provide a reliable and efficient service to our patients.
                            We ensure that our drivers are highly trained and equipped with the necessary
                            medical equipment to handle any emergency situation that may arise.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Work
