import React from 'react';
import { AppBar, Toolbar, Typography, IconButton } from '@mui/material';
import face from '../assets/svg/facebook.png';
import twit from '../assets/svg/twitter.png';
import link from '../assets/svg/linkdin.png';
import yout from '../assets/svg/youtube.png';
import logo from '../assets/svg/white-logo.png'
import './navHead.css';
const Footer = () => {

  return (
    <div className='container-fluid' id='contact'>
      <div className='row px-4 rowFluid py-2' style={{ background: 'red', alignItems: 'center' }}>

        <div className='col-sm-3 rowCol2'>
          <p style={{ whiteSpace: 'nowrap' }}>
            Copyright 2023 Medicar. All Rights
          </p>
        </div>
        <div className='col-sm-7 text-center rowCol3'>
          <img style={{ width: '40px' }} src={logo} />
        </div>
        <div className='col-sm-2 rowCol1' >
          <a className='mx-2' href=''><img src={face} /></a>
          <a className='mx-2' href=''><img src={twit} /></a>
          <a className='mx-2' href=''><img src={link} /></a>
          <a className='mx-2' href=''><img src={yout} /></a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
