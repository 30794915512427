import React from 'react';
import { useState } from 'react';
import { FormControl, FormControlLabel, Radio, RadioGroup, Button } from '@mui/material';
import img from '../assets/images/img2.png';
import bgImg from '../assets/images/img3.png';
import { makeStyles } from '@mui/styles';
import './learnmore.css'


const RowComponent = () => {

    const [selectedOption, setSelectedOption] = useState(['option1', 'option2']);

    const handleChange = (event) => {
        setSelectedOption(event.target.value);
    };

    return (
        <div className='container-fluid background-img'>

            <div className='container my-3'>
                <div className='row padd '>
                    <div className='col-lg-6 col-sm-12'>
                        <img
                            className='img-fluid'
                            src={img} />
                    </div>
                    <div className='col-lg-6 col-sm-12'>
                        <h4>
                            <span className='h3_black'>Our Commitment to Being Safe, Punctual, and Reliable is a Top </span>

                            <span className='h3_red'>Priority in Everything We Do.</span>
                        </h4>
                        <p className='text'>
                            We are commited to innovation and work hard to stay ahead of the curve
                        </p>
                        <FormControl component="fieldset" error>
                            <RadioGroup value={selectedOption} onChange={handleChange}>
                                <FormControlLabel
                                    className='radio_btn'
                                    value="option1"
                                    size='small'
                                    control={<Radio color="error" />}
                                    label="We strive to exceed expectations"
                                />
                                <FormControlLabel
                                    className='radio_btn'
                                    value="option2"
                                    size='small'
                                    control={<Radio color="error" />}
                                    label="Our commitment to being safe and punctual is a top priority in everything we do"
                                />
                            </RadioGroup>
                        </FormControl>
                        <Button
                            size='large'
                            color='error'
                            variant='outlined'
                            sx={{ marginTop: '0.5rem', background: '#FCEAEA', fontWeight: 'bold' }}
                        >Learn More</Button>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default RowComponent;
