import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import './Nav.css';
import logo from "../assets/svg/logo.svg"
// import { faFacebook, faInstagram, faYoutube } from '@fortawesome/free-brands-svg-icons';

const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const hrefggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <nav className="navbar">
      <div className="navbar-logo">
        <img src={logo} alt="Logo" />
      </div>
      <div className={`navbar-menu ${menuOpen ? 'open' : ''}`}>
        <ul>
          <li>
            <a href="#home" onClick={hrefggleMenu}>Home</a>
          </li>
          <li>
            <a href="#work" onClick={hrefggleMenu}>About</a>
          </li>
          <li>
            <a href="#blogs" onClick={hrefggleMenu}>Customer</a>
          </li>
          <li>
            <a href="#services" onClick={hrefggleMenu}>Services</a>
          </li>
          <li>
            <a href="#contact" onClick={hrefggleMenu}>Contact</a>
          </li>
        </ul>
      </div>

      {/* <div className='icons'>
        <a href='https://web.facebook.com/swaywithpray/?_rdc=1&_rdr' target="_blank">
          <FontAwesomeIcon icon={faFacebook} className='iconsfont' />
        </a>
        <a href='https://www.instagram.com/swaywithpray/?hl=en' target="_blank">
          <FontAwesomeIcon icon={faInstagram} className='iconsfont' />
        </a>
        <a href='https://www.youtube.com/channel/UC04eJMg0UhmhrvXfiXyvW5g' target="_blank">
          <FontAwesomeIcon icon={faYoutube} className='iconsfont' />
        </a>
      </div> */}
      <div className="navbar-menu-button" onClick={hrefggleMenu}>
        <FontAwesomeIcon icon={faBars} />
      </div>
    </nav>
  );
};

export default Navbar;
