import React from 'react';
import { makeStyles } from '@mui/styles';
import { Button, Container, Typography } from '@mui/material';
import bgImg from '../assets/images/img1.png';

import './banner.css'

const Home = () => {

  return (
    <div className='container-fluid' id='home'>
      <div className='row'>
        <div className='col content-bgImg'>
          <div className='content_ctr'>
            <Typography variant="h1" className='banner-heading'>
              Elevating Medical Transport Through Advanced Technology.
            </Typography>
            <Typography variant="body1" className='banner-text py-3'>
              Revolutionizing healthcare transportation by providing safe, reliable, and efficient transportation for medical facilities through our innovative technology platform.
            </Typography>
            <Button className='banner_btn mt-1'>
              Get Started Now
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;