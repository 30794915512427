import React from 'react';
import { AppBar, Toolbar, Typography, IconButton } from '@mui/material';
import face from '../assets/svg/facebook.png';
import twit from '../assets/svg/twitter.png';
import link from '../assets/svg/linkdin.png';
import yout from '../assets/svg/youtube.png';
import './navHead.css';
const NavHead = () => {

  return (
    <div className='container-fluid'>
      <div className={'row rowFluid py-2'} style={{ background: 'red', alignItems: 'center' }}>
        <div className='col-sm-2 rowCol1' >
          <a className='mx-2' href=''><img src={face} /></a>
          <a className='mx-2' href=''><img src={twit} /></a>
          <a className='mx-2' href=''><img src={link} /></a>
          <a className='mx-2' href=''><img src={yout} /></a>
        </div>
        <div className='col-sm-8 rowCol2'>
          <Typography variant="body1">
            Monday to Friday 10:30 am to 6:00 Pm
          </Typography>
        </div>
        {/* <div className='col-sm-2 rowCol3'>
          <Typography variant="body1" >
            Call 01564731035
          </Typography>
        </div> */}
      </div>
    </div>
  );
};

export default NavHead;
